import Swiper from 'swiper';
import { Navigation, Virtual, A11y } from 'swiper/modules';
import 'swiper/css';

const defaultOptions = {
	modules: [Navigation, Virtual, A11y],

	direction: 'horizontal',
	loop: false,
	slidesPerView: 1,
	spaceBetween: 16,
	grabCursor: true,

	breakpoints: {
		601: {
			slidesPerView: 2,
		},
		801: {
			slidesPerView: 3,
		},
		1201: {
			slidesPerView: 4,
		},
	},

	navigation: {
		nextEl: '.swiper-alt-navigation .swiper-button-next',
		prevEl: '.swiper-alt-navigation .swiper-button-prev',
	},

	a11y: {
        prevSlideMessage: 'Forrige slide',
        nextSlideMessage: 'Næste slide',
    },
};

document.querySelectorAll('.blocklist-component-carousel .swiper').forEach(function (el) {
	var swiper = new Swiper(el, defaultOptions);
});
