import { closeMenu } from "./mobile-menu";

const searchModal = document.getElementById('search-modal');
const searchButton = document.querySelectorAll('[data-open-search-modal]');
const closeButton = document.querySelector('[data-close-search-modal]');

let isSearchExpanded = false;

function closeSearchModal(){
    isSearchExpanded = false;
    searchModal.setAttribute('tabindex','-1');
    searchModal.setAttribute('inert','');
    searchModal.setAttribute('aria-expanded','false');
    searchModal.classList.remove('active');
    document.body.classList.remove('no-scroll');
}

searchButton.forEach((element) =>
    element.addEventListener('click', function() {
        closeMenu();

        if(isSearchExpanded && !element.closest('#mobile-navigation')){
            closeSearchModal()
        }
        else {
            isSearchExpanded = true;
            searchModal.setAttribute('tabindex','1');
            searchModal.removeAttribute('inert');
            searchModal.setAttribute('aria-expanded','true');
            searchModal.classList.add('active');
            document.body.classList.add('no-scroll');
            searchModal.querySelector('[data-search-query]').focus();
        }
    })
)

document.addEventListener('keydown', function(e) {
    if (e.key === 'Escape' && searchModal.classList.contains('active')) {
        closeSearchModal();
    }
});

window.addEventListener('resize', function(e) {
    closeSearchModal();
});

closeButton.addEventListener('click', function() {
    closeSearchModal();
});
