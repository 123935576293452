const mobileMenu = document.querySelector('.mobile-navigation')
const mobileCloseButton = document.querySelector('.mobile-close-button')
const mobileMenuButton = document.querySelector('[data-mobile-menu-toggle]')
const mobileBackButton = document.querySelectorAll('button.back-button')
const underlay = document.getElementById('mobile-navigation-underlay')

let isMobileMenuExpanded = false

function openMenu() {
    isMobileMenuExpanded = true
    document.body.classList.add('no-scroll')
    underlay.classList.add('active')
    mobileMenu.classList.add('active')
    mobileMenu.removeAttribute('inert')
    mobileMenu.setAttribute('aria-expanded', 'true')
    mobileMenu.querySelector('.mobile-close-button').focus()
}

export function closeMenu() {
    isMobileMenuExpanded = false
    document.body.classList.remove('no-scroll')
    underlay.classList.remove('active')
    mobileMenu.classList.remove('active')
    mobileMenu.setAttribute('inert', '')
    mobileMenu.setAttribute('aria-expanded', 'false')
}

mobileMenuButton.addEventListener('click', function () {
    openMenu()
});

[mobileCloseButton, underlay].forEach((element) =>
    element.addEventListener('click', closeMenu),
)

document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') closeMenu()
})