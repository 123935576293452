document.querySelectorAll(".video").forEach(function (el) {
    if(el.querySelector("[data-play-toggle]")){
        el.querySelector("[data-play-toggle]").addEventListener("click", (ev) => {
            ev.preventDefault();
            el.classList.add('playing');
            
            let iframe = el.querySelector("iframe");
            let url = iframe.getAttribute("src");
            iframe.setAttribute("src", url + "?autoplay=1");
        });
    }
});