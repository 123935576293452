const accordions = document.querySelectorAll('[data-accordion]')

function setHeightAndOpacity(accordionContent) {
    accordionContent.style.height = accordionContent.scrollHeight + 'px'
    accordionContent.style.opacity = '1'
}

accordions.forEach(accordion => {
    const toggler = accordion.querySelector('[data-accordion-toggler]')
    const toggleIcons = toggler.querySelector('.toggler-icons')
    const accordionContent = accordion.querySelector('[data-accordion-content]')
    let isTransitioning = false
    let accordionContentOpen = false

    toggler.addEventListener('click', () => {
        if (isTransitioning) return

        if (!accordionContentOpen) {
            toggleIcons.classList.toggle('closed')
            toggleIcons.classList.toggle('opened')
            accordionContent.classList.remove('hidden')
            accordionContent.classList.add('is-animating')

            setHeightAndOpacity(accordionContent)
        } else {
            toggleIcons.classList.toggle('closed')
            toggleIcons.classList.toggle('opened')
            setHeightAndOpacity(accordionContent)

            setTimeout(e => {
                accordionContent.style.height = '0px'
                accordionContent.style.opacity = '0'
            }, 125)
        }

        isTransitioning = true
    })

    accordionContent.addEventListener('transitionend', e => {
        if (e.propertyName !== 'height') return

        isTransitioning = false
        accordionContentOpen = !accordionContentOpen
        toggler.toggleAttribute('aria-expanded')
        accordionContent.toggleAttribute('aria-hidden')
        accordionContent.style = null

        accordionContentOpen ? accordionContent.classList.remove('is-animating') : accordionContent.classList.add('hidden')
    })
})

document.addEventListener('DOMContentLoaded', function () {
    const hash = window.location.hash.substring(1);

    if (hash) {
        const accordion = document.getElementById(hash);

        if (accordion && accordion.classList.contains('accordion')) {
            const toggler = accordion.querySelector('[data-accordion-toggler]');

            if (toggler) {
                toggler.click();
                accordion.scrollTo({ behavior: 'smooth' });
            }
        }
    }
});