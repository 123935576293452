const accordions = document.querySelectorAll('[data-mobile-nav-accordion]'),
      transitionDuration = window.getComputedStyle(document.querySelector('[data-mobile-nav-accordion-content]')).transitionDuration * 1000 || 200;

function setHeightAndOpacity(accordionContent) {
  accordionContent.style.height = accordionContent.scrollHeight + 'px'
  accordionContent.style.opacity = '1'
}

function handleToggle(event) {
  const toggler = event.target.closest('[data-mobile-nav-accordion-toggler]')
  if (!toggler) return
  const accordion = toggler.closest('[data-mobile-nav-accordion]')
  if (!accordion) return

  const toggleIcons = toggler.querySelector('.toggler-icons')
  const accordionContent = accordion.querySelector('[data-mobile-nav-accordion-content]')
  const isTransitioning = toggler.getAttribute('data-transitioning') === 'true'
  const accordionContentOpen = toggler.getAttribute('aria-expanded') === 'true'
  if (isTransitioning) return

  toggler.setAttribute('data-transitioning', 'true')
  if (!accordionContentOpen) {
      toggler.classList.toggle('closed')
      toggler.classList.toggle('opened')
      accordionContent.classList.remove('hidden')
      accordionContent.classList.add('is-animating')

      setHeightAndOpacity(accordionContent)
  } else {
      toggler.classList.toggle('closed')
      toggler.classList.toggle('opened')
      setHeightAndOpacity(accordionContent)

      setTimeout(() => {
        accordionContent.style.height = '0px'
        accordionContent.style.opacity = '0'
      }, transitionDuration)
    }

  accordionContent.addEventListener('transitionend', function onTransitionEnd(e) {
    if (e.propertyName !== 'height') return

    accordionContent.removeEventListener('transitionend', onTransitionEnd)

    toggler.setAttribute('data-transitioning', 'false')
    const nowOpen = toggler.getAttribute('aria-expanded') !== 'true'
    toggler.setAttribute('aria-expanded', nowOpen ? 'true' : 'false')
    accordionContent.toggleAttribute('aria-hidden')
    accordionContent.style = null

    if (nowOpen) {
      accordionContent.classList.remove('is-animating')
    } else {
      accordionContent.classList.add('hidden')
    }
  }, { once: true })
}

document.addEventListener('click', handleToggle)

function expandCurrentPageParentAccordions() {
  const currentPageLink = document.querySelector('a.current-page')
  if (!currentPageLink) return
  
  const togglersToClick = []
  let parentElement = currentPageLink.parentElement
  while (parentElement) {
    const parentAccordion = parentElement.closest('[data-mobile-nav-accordion]')
    if (!parentAccordion) break

    const toggler = parentAccordion.querySelector('[data-mobile-nav-accordion-toggler]')
    if (toggler && toggler.getAttribute('aria-expanded') !== 'true') {
      togglersToClick.unshift(toggler)
    }
    parentElement = parentAccordion.parentElement
  }

  function clickWithDelay(index) {
    if (index >= togglersToClick.length) return
    togglersToClick[index].click()
    setTimeout(() => clickWithDelay(index + 1), transitionDuration)
  }

  clickWithDelay(0)
}

document.addEventListener('DOMContentLoaded', expandCurrentPageParentAccordions)