const mainHeader = document.getElementById('main-header'); // Ensure you select the header element
let previousScrollPosition = window.scrollY;

window.addEventListener('scroll', function () {
  let currentScrollPosition = window.scrollY;
  let headerHeight = mainHeader.offsetHeight;

  if (currentScrollPosition > headerHeight) {
    if (currentScrollPosition > previousScrollPosition) {
      // Scrolling down
      mainHeader.classList.add('hidden');
    } else {
      // Scrolling up
      mainHeader.classList.remove('hidden');
    }
  }

  previousScrollPosition = currentScrollPosition;
});